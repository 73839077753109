<template>
  <div class="officialCommunity">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">官方社区</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="container" v-if="list.length">
        <div class="appBox" v-for="(item, index) in list" :key="index">
          <div class="appItem">
            <ImgDecypt class="appLogo" :src="item.officialImg" />
            <div class="appName">{{ item.officialName }}</div>
          </div>
          <div class="appBtn" @click="jump(item.officialUrl)">立即加入</div>
        </div>
      </div>
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import { officialList } from '@/api/mine';
import ImgDecypt from '@/components/ImgDecypt';
import NoData from '@/components/NoData';
import { Toast } from 'vant';
export default {
  name: 'OfficialCommunity',
  components: {
    ImgDecypt,
    NoData,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let req = { type: 2 }; // 精品应用-1 官方社群-2
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(officialList, req);
        if (res && res.code == 200) {
          this.list = res.data ? res.data : [];
          this.$store.commit('app/SET_LOADING', false);
        } else {
          Toast(res.tip || '请求失败');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('请求失败');
      }
    },
    // 跳转
    jump(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.officialCommunity {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);

    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 20px;
      text-align: center;
      padding: 24px 13px;
      .appBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .appItem {
          width: 109px;
          height: 109px;
          background-color: rgb(226, 242, 245);
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          .appLogo {
            width: 61px;
            height: 61px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            z-index: 1;
          }
          .appName {
            font-size: 16px;
            width: 82px;
            color: rgb(37, 140, 182);
            overflow: hidden;
            /*文本不会换行*/
            white-space: nowrap;
            /*当文本溢出包含元素时，以省略号表示超出的文本*/
            text-overflow: ellipsis;
          }
        }
        .appBtn {
          margin-top: 8px;
          width: 92px;
          height: 32px;
          font-size: 14px;
          line-height: 32px;
          border-radius: 4px;
          text-align: center;
          color: #fff;
          background-color: rgb(127, 220, 234);
        }
      }
    }
  }
}
</style>
